import React from 'react';
import { string, shape, object } from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { HeaderContainer } from '../HeaderContainer';
import { TeaserContainer } from './TeaserContainer';
import { TeaserHeader } from './TeaserHeader';

export function EntryTeaser({ frontmatter, fields, excerpt }) {
  const title = frontmatter.title || fields.slug
  const featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid;
  const entryUrl = fields.slug;

  return (
    <TeaserContainer>
      <Link to={entryUrl} title={title}>
        <Img fluid={featuredImgFluid} />
      </Link>
      <HeaderContainer>
        <small>{frontmatter.date}</small>
        <Link to={entryUrl} title={title}>
          <TeaserHeader>{title}</TeaserHeader>
        </Link>
      </HeaderContainer>
      <p dangerouslySetInnerHTML={{ __html: excerpt }} />
    </TeaserContainer>
  )
}

EntryTeaser.propTypes = {
  frontmatter: shape({
    title: string,
    date: string.isRequired,
    featuredImage: shape({
      childImageSharp: shape({
        fluid: object,
      }),
    }),
  }).isRequired,
  fields: shape({
    slug: string.isRequired,
  }).isRequired,
  excerpt: string.isRequired,
};

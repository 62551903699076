import { styled } from 'styletron-react';
import { rhythm } from '../../utils/typography';

export const TeaserHeader = styled('span', {
  marginBottom: rhythm(1 / 8),
  display: 'block',
  fontSize: '1.51572rem',
  fontWeight: '700',
  textRendering: 'optimizeLegibility',
  lineHeight: '1.1',
});

import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/Layout/Layout';
import { SeoTags } from '../components/SeoTags';
import { EntryTeaser } from '../components/EntryTeaser/EntryTeaser';

const keywords = [
  `JavaScript`,
  'JS',
  `frontend`,
  `Haskell`,
  `Wojciech Sołtysiak`,
  'programowanie',
  'development',
  'developer',
  'blog',
];

export default function Home({ data }) {
  const description = data.site.siteMetadata.description
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout description={description}>
      <SeoTags
        title="Najnowsze artykuły"
        description={description}
        keywords={keywords}
      />
      {posts.map(
        ({ node }) => <EntryTeaser key={node.fields.slug} {...node} />
      )}
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 280)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "PL_pl")
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 590, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
